import { Alert, Autocomplete, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import * as APIServices from "../../services/rest/APIServices";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from "../../routes/routes";
import Loading from '../../components/Loading';
import { useContext } from 'react';
import EducationContext from '../../context/EducationContext';
import ApplicationContext from '../../context/ApplicationContext';
import { MIN_DATE, handlePreventTypingChars } from '../../utils/Validations';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import axios from 'axios';
import ULButton from '../../components/ULButton';
import { SCROLLABLE_AREA_NAME, canUserEditInformation } from '../../utils/CommonFunctions';

const EducationLocalSchoolData = ({ grade = "fifth" }) => {
  const { applicationID, correcting } = useContext(ApplicationContext);
  const { fifthGradeSchool, setFifthGradeSchool, fourthGradeSchool, setFourthGradeSchool, thirdGradeSchool, setThirdGradeSchool, bachelorship, setBachelorship } = useContext(EducationContext);
  const [schoolHasBachelorship, setSchoolHasBachelorship] = useState(false);
  const currentYear = new Date().getFullYear();
  const [errMessages, setErrMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errFifthYear, setErrFifthYear] = useState("");
  const [errFourthYear, setErrFourthYear] = useState("");
  const [errThirdYear, setErrThirdYear] = useState("");
  const [errBachelorshipYear, setErrBachelorshipYear] = useState("");

  const [school, setSchool] = React.useState();
  const [openSchoolList, setOpenSchoolList] = React.useState(false);
  const [schoolList, setSchoolList] = React.useState([]);
  const [loadingSearchResultsSchools, setLoadingSearchResultsSchools] = React.useState(false);
  const [searchKeySchool, setSearchKeySchool] = React.useState('');

  const [geolocation, setGeolocation] = React.useState();
  const [openGeolocationList, setOpenGeolocationList] = React.useState(false);
  const [geolocationList, setGeolocationList] = React.useState([]);
  const [loadingSearchResultsGeolocation, setLoadingSearchResultsGeolocation] = React.useState(false);
  const [searchKeyGeolocation, setSearchKeyGeolocation] = React.useState('');
  const [working, setWorking] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  const navigate = useNavigate();

  const { t } = useTranslation();


  /* Geolocation */
  const handleSearchGeolocation = (e) => {
    setSearchKeyGeolocation(e.target.value);
  };

  React.useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    const callServices = async () => {
      setLoadingSearchResultsGeolocation(true);
      await APIServices.getGeolocationAutocompleteList(searchKeyGeolocation, cancelTokenSource).then((response) => {
        if (response.data.data?.list?.length) {
          const results = response.data.data.list;
          setGeolocationList(results);
        }
        else {
          setGeolocationList([]);
        }
      })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            // setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
            // const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
            // scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
            // window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        })
        .finally(() => {
          setLoadingSearchResultsGeolocation(false);
        })
    }

    if (searchKeyGeolocation?.length >= 3) {
      callServices();
    } else {
      setGeolocationList([]);
      setLoadingSearchResultsGeolocation(false);
    }
    return () => cancelTokenSource.cancel(t('common.autocomplete.aborted'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyGeolocation]);

  const handleGeolocationChange = (event, value) => {
    setGeolocation(value);
  };

  React.useEffect(() => {
    if (grade === "fifth") {
      setFifthGradeSchool({
        ...fifthGradeSchool,
        geolocation: geolocation ? geolocation.geolocation : '',
      })
      if (fifthGradeSchool.sameSchool) {
        setFourthGradeSchool({
          ...fourthGradeSchool,
          geolocation: geolocation ? geolocation.geolocation : '',
        })
        setThirdGradeSchool({
          ...thirdGradeSchool,
          geolocation: geolocation ? geolocation.geolocation : '',
        })
      }
    }
    if (grade === "fourth") {
      setFourthGradeSchool({
        ...fourthGradeSchool,
        geolocation: geolocation ? geolocation.geolocation : '',
      })
      if (fourthGradeSchool.sameSchool) {
        setThirdGradeSchool({
          ...thirdGradeSchool,
          geolocation: geolocation ? geolocation.geolocation : '',
        })
      }
    }
    if (grade === "third") {
      setThirdGradeSchool({
        ...thirdGradeSchool,
        geolocation: geolocation ? geolocation.geolocation : '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geolocation]);

  /* School */
  const handleSearchSchool = (e) => {
    setSearchKeySchool(e.target.value);
  };

  React.useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    const callServices = async () => {
      setLoadingSearchResultsSchools(true);
      await APIServices.getSchoolAutocompleteList(searchKeySchool, geolocation?.geolocation ?? '', cancelTokenSource).then((response) => {
        if (response.data.data?.list?.length) {
          const results = response.data.data.list;
          setSchoolList(results);
        }
        else {
          setSchoolList([]);
        }
      })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            // setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
            // const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
            // scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
            // window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        })
        .finally(() => {
          setLoadingSearchResultsSchools(false);
        })
    }

    setGeolocation({geolocation: ""});
    setGeolocationList([]);
    setSearchKeyGeolocation('');
    setSchoolHasBachelorship(false);

    if (searchKeySchool?.length >= 3) {
      callServices();
    } else {
      setSchoolList([]);
      setLoadingSearchResultsSchools(false);
    }
    return () => cancelTokenSource.cancel(t('common.autocomplete.aborted'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeySchool]);

  const handleSchoolChange = (event, value) => {
    setSchool(value);
    // setSchoolList([]);
  };

  React.useEffect(() => {
    if (grade === "fifth") {
      setFifthGradeSchool({
        ...fifthGradeSchool,
        schoolId: school ? school.schoolId : 0,
      })
      if (fifthGradeSchool.sameSchool) {
        setFourthGradeSchool({
          ...fourthGradeSchool,
          schoolId: school ? school.schoolId : 0,
        })
        setThirdGradeSchool({
          ...thirdGradeSchool,
          schoolId: school ? school.schoolId : 0,
        })
      }
    }
    if (grade === "fourth") {
      setFourthGradeSchool({
        ...fourthGradeSchool,
        schoolId: school ? school.schoolId : 0,
      })
      if (fourthGradeSchool.sameSchool) {
        setThirdGradeSchool({
          ...thirdGradeSchool,
          schoolId: school ? school.schoolId : 0,
        })
      }
    }
    if (grade === "third") {
      setThirdGradeSchool({
        ...thirdGradeSchool,
        schoolId: school ? school.schoolId : 0,
      });
    }

    setGeolocation({ geolocation: school?.geolocation ?? '' });
    verifyBachelorship(school ? school.schoolId : 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school]);



  const verifyBachelorship = (schoolID) => {
    if (!schoolID) return;
    APIServices.hasBaccalaureate(applicationID, schoolID)
      .then((response) => {
        const bachelorshipRequired = response.data.data?.required ?? false;
        setSchoolHasBachelorship(bachelorshipRequired);
      })
      .catch((error) => {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
      })
  }

  /*************************************************************+*/


  const handleChangeSchoolNotFound = () => {
    if (grade === "fifth") {
      const notFound = fifthGradeSchool.notFound;
      setFifthGradeSchool({
        ...fifthGradeSchool,
        schoolId: 0,
        name: '',
        address: '',
        notFound: !notFound,
      })
      if (fifthGradeSchool.sameSchool) {
        setFourthGradeSchool({
          ...fourthGradeSchool,
          schoolId: 0,
          name: '',
          address: '',
          notFound: !notFound,
        })
        setThirdGradeSchool({
          ...thirdGradeSchool,
          schoolId: 0,
          name: '',
          address: '',
          notFound: !notFound,
        })
      }
    }
    if (grade === "fourth") {
      const notFound = fourthGradeSchool.notFound;
      setFourthGradeSchool({
        ...fourthGradeSchool,
        schoolId: 0,
        name: '',
        address: '',
        notFound: !notFound,
      })
      if (fourthGradeSchool.sameSchool) {
        setThirdGradeSchool({
          ...thirdGradeSchool,
          schoolId: 0,
          name: '',
          address: '',
          notFound: !notFound,
        })
      }
    }
    if (grade === "third") {
      const notFound = thirdGradeSchool.notFound;
      setThirdGradeSchool({
        ...thirdGradeSchool,
        schoolId: 0,
        name: '',
        address: '',
        notFound: !notFound,
      })
    }
    // Necesario para limpiar el campo de texto al elegir que no se encontró el colegio
    setSchoolList([]);
    setSchool({ schoolId: 0, name: '', address: '', geolocation: '' });
    setSearchKeySchool('');
    setGeolocationList([]);
    setSearchKeyGeolocation('');
    // setGeolocation(null);
  };

  /*************************************************************+*/


  const checkValidBacheloshipYear = (value) => {
    const v_str = value.toString() ?? '';
    const currentYear = new Date().getFullYear();
    if (v_str.length === 4 && (value < fifthGradeSchool.schoolYear)) {
      setErrBachelorshipYear(t('education.bachelorship.invalid-year-err-msg'));
    }
    else if (v_str.length === 4 && (value > currentYear))
    {
      setErrBachelorshipYear(t('education.bachelorship.invalid-year-current-year-err-msg'));
    }
    else {
      setErrBachelorshipYear('');
    }
  }

  const checkValidFifthYear = (value) => {
    const v_str = value.toString() ?? '';
    if (v_str.length === 4 && (value > currentYear || value < MIN_DATE)) {
      setErrFifthYear(t('education.fifth-year.fifth-year-information.invalid-year-err-msg'));
    }
    else {
      setErrFifthYear('');
    }
  }

  const handleFifthYearChange = (event) => {
    setFifthGradeSchool({
      ...fifthGradeSchool,
      schoolYear: !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : 0,
    })
    if (event.target.value.length === 4 && fifthGradeSchool.sameSchool) {
      setFourthGradeSchool({
        ...fifthGradeSchool,
        schoolYear: parseInt(event.target.value) > 0 ? (parseInt(event.target.value) - 1) : 0,
      });
      setThirdGradeSchool({
        ...fifthGradeSchool,
        schoolYear: parseInt(event.target.value) > 0 ? (parseInt(event.target.value) - 2) : 0,
      });
    }
  };

  const checkValidFourthYear = (value) => {
    const v_str = value.toString() ?? '';
    if (v_str.length === 4 && (value >= fifthGradeSchool.schoolYear || value < MIN_DATE)) {
      setErrFourthYear(t('education.fourth-year.fourth-year-information.invalid-year-err-msg', { date: MIN_DATE }));
    }
    else {
      setErrFourthYear('');
    }
  }

  const handleFourthYearChange = (event) => {
    setFourthGradeSchool({
      ...fourthGradeSchool,
      schoolYear: !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : 0,
    })
    if (event.target.value.length === 4 && fourthGradeSchool.sameSchool) {
      setThirdGradeSchool({
        ...fifthGradeSchool,
        schoolYear: parseInt(event.target.value) > 0 ? (parseInt(event.target.value) - 1) : 0,
      });
    }
  };

  const checkValidThirdYear = (value) => {
    const v_str = value.toString() ?? '';
    if (v_str.length === 4 && (value >= fourthGradeSchool.schoolYear || value < MIN_DATE)) {
      setErrThirdYear(t('education.third-year.third-year-information.invalid-year-err-msg', { date: MIN_DATE }));
    }
    else {
        setErrThirdYear('');
    }
  }

  const handleThirdYearChange = (event) => {
    setThirdGradeSchool({
      ...thirdGradeSchool,
      schoolYear: !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : 0,
    })
  };

  React.useEffect(() => {
    if (grade === "fifth")
      checkValidFifthYear(fifthGradeSchool.schoolYear)
    if (grade === "fourth")
      checkValidFourthYear(fourthGradeSchool.schoolYear)
    if (grade === "third")
      checkValidThirdYear(thirdGradeSchool.schoolYear)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fifthGradeSchool, fourthGradeSchool, thirdGradeSchool])

  React.useEffect(() => {
    if (grade === "fifth" ? fifthGradeSchool.sameSchool : fourthGradeSchool.sameSchool) {
      if (grade === "fifth") {
        setFourthGradeSchool({
          ...fifthGradeSchool,
          schoolYear: fifthGradeSchool.schoolYear > 0 ? (fifthGradeSchool.schoolYear - 1) : 0,
        });
        setThirdGradeSchool({
          ...fifthGradeSchool,
          schoolYear: fifthGradeSchool.schoolYear > 0 ? (fifthGradeSchool.schoolYear - 2) : 0,
        });
        setErrFourthYear('');
      }
      if (grade === "fourth") {
        setThirdGradeSchool({
          ...fourthGradeSchool,
          schoolYear: fourthGradeSchool.schoolYear > 0 ? (fourthGradeSchool.schoolYear - 1) : 0,
        });
      }
      setErrThirdYear('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fifthGradeSchool.sameSchool, fourthGradeSchool.sameSchool])

  const handleSameSchoolChange = (e) => {
    if (grade === "fifth") {
      setFifthGradeSchool({
        ...fifthGradeSchool,
        sameSchool: e.target.value === 'yes',
      });
    }
    if (grade === "fourth") {
      setFourthGradeSchool({
        ...fourthGradeSchool,
        sameSchool: e.target.value === 'yes',
      });
    }
  };

  const handleHasBachelorshipChange = (e) => {
    setBachelorship({
      ...bachelorship,
      hasBachelorship: e.target.value,
      bachelorshipYear: 0,
    });
  };

  const handleBachelorshipYearChange = (event) => {
    setBachelorship({
      ...bachelorship,
      bachelorshipYear: !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : 0,
    });
  };

  useEffect(() => {
    checkValidBacheloshipYear(bachelorship.bachelorshipYear);
  }, [bachelorship]);

  const transformFetchData = (data) => {
    const resData = {
      schoolId: data.school?.schoolId ?? 0,
      schoolType: data.schoolType ?? 0,
      schoolYear: data.schoolYear ?? 0,
      notFound: data?.notFound,
      geolocation: data.geolocation ?? '',
      applicantSchoolId: data.applicantSchoolId ?? 0,
      sameSchool: data?.sameSchool,
    }
    return resData;
  }

  const fetchData = async () => {
    if (!applicationID) return;
    setIsLoading(true);
    await APIServices.fetchEducationInfo(grade, applicationID)
      .then((response) => {
        const data = response.data.data;
        const gradeSchool = transformFetchData(data);
        if (grade === "fifth") {
          const baccalaureateYear = response.data.data?.baccalaureateYear ?? 0;
          setFifthGradeSchool(gradeSchool);
          if (gradeSchool.sameSchool) {
            const gradeSchoolFourth = { ...gradeSchool }
            gradeSchoolFourth.schoolYear = data.fourthGradeSchoolYear;
            setFourthGradeSchool(gradeSchoolFourth);
            const gradeSchoolThird = { ...gradeSchool }
            gradeSchoolThird.schoolYear = data.thirdGradeSchoolYear;
            setThirdGradeSchool(gradeSchoolThird);
          }

          if (baccalaureateYear) {
            setBachelorship({
              ...bachelorship,
              hasBachelorship: "yes",
              bachelorshipYear: baccalaureateYear,
            });
          }

          setSearchKeySchool(data.school.name);
          setSchool(data.school.schoolId ? data.school : null);
          setSearchKeyGeolocation(data.geolocation);
          setGeolocation({ geolocation: gradeSchool.geolocation });
        }
        else if (grade === "fourth") {
          setFourthGradeSchool(gradeSchool);
          if (gradeSchool.sameSchool) {
            const gradeSchoolThird = { ...gradeSchool }
            gradeSchoolThird.schoolYear = data.thirdGradeSchoolYear;
            setThirdGradeSchool(gradeSchoolThird);
          }
          setSearchKeySchool(data.school.name);
          setSchool(data.school.schoolId ? data.school : null);
          setSearchKeyGeolocation(data.geolocation);
          setGeolocation({ geolocation: gradeSchool.geolocation });
        }
        else {
          setThirdGradeSchool(gradeSchool);
          setSearchKeySchool(data.school.name);
          setSchool(data.school.schoolId ? data.school : null);
          setSearchKeyGeolocation(data.geolocation);
          setGeolocation({ geolocation: gradeSchool.geolocation });
        }
        const status = response.data.data?.status;
        setCanEdit(canUserEditInformation(status));
      })
      .catch((error) => {
        // No se pudo obtener la info (aún no ha sido registrada)
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformSaveData = (gradeSchool) => {
    const data = {
      applicationId: applicationID,
      schoolId: gradeSchool.schoolId,
      schoolType: gradeSchool.schoolType,
      schoolYear: gradeSchool.schoolYear,
      notFound: gradeSchool?.notFound,
      geolocation: gradeSchool?.geolocation ?? '',
      sameSchool: gradeSchool?.sameSchool,
      fourthGradeSchoolYear: fourthGradeSchool.schoolYear,
      thirdGradeSchoolYear: thirdGradeSchool.schoolYear,
      baccalaureateYear: (grade === "fifth" && schoolHasBachelorship) ? bachelorship.bachelorshipYear : 0,
    }
    return data;
  }

  const saveEducationInfo = (e) => {
    const data = transformSaveData(grade === "fifth" ? fifthGradeSchool : grade === "fourth" ? fourthGradeSchool : thirdGradeSchool);
    if (data.schoolId === 0)
      delete data.schoolId
    setWorking(true);
    setErrMessages([]);

    APIServices.saveEducationInfo(grade, data)
      .then((response) => {
        const data = response.data.data;
        if (grade === "fifth") {
          setFifthGradeSchool({
            ...fifthGradeSchool,
            applicantSchoolId: data?.applicantSchoolId ?? 0,
          })
          if (fifthGradeSchool.notFound) {
            navigate(ROUTES.EDUCATION_FIFTH_SCHOOL_NOT_FOUND);
          }
          else {
            if (fifthGradeSchool.sameSchool) {
              if (correcting) {
                navigate(ROUTES.APPLICATION_SUMMARY);
              }
              else
                navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
            }
            else
              navigate(ROUTES.EDUCATION_FOURTH_SCHOOL_TYPE);
          }
        }
        if (grade === "fourth") {
          setFourthGradeSchool({
            ...fourthGradeSchool,
            applicantSchoolId: data?.applicantSchoolId ?? 0,
          })
          if (fourthGradeSchool.notFound) {
            navigate(ROUTES.EDUCATION_FOURTH_SCHOOL_NOT_FOUND);
          }
          else {
            if (fourthGradeSchool.sameSchool) {
              if (correcting)
                navigate(ROUTES.APPLICATION_SUMMARY);
              else
                navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
            }
            else
              navigate(ROUTES.EDUCATION_THIRD_SCHOOL_TYPE);
          }
        }
        if (grade === "third") {
          setThirdGradeSchool({
            ...thirdGradeSchool,
            applicantSchoolId: data?.applicantSchoolId ?? 0,
          })
          if (thirdGradeSchool.notFound)
            navigate(ROUTES.EDUCATION_THIRD_SCHOOL_NOT_FOUND);
          else {
            if (correcting)
              navigate(ROUTES.APPLICATION_SUMMARY);
            else
              navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
          }
        }
      })
      .catch((error) => {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() => {
        setWorking(false);
      })
  }

  const navigateTo = () => {
    if (grade === "fifth") {
      if (fifthGradeSchool.notFound) {
        navigate(ROUTES.EDUCATION_FIFTH_SCHOOL_NOT_FOUND);
      }
      else {
        if (fifthGradeSchool.sameSchool) {
          if (correcting)
            navigate(ROUTES.APPLICATION_SUMMARY);
          else
            navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
        }
        else
          navigate(ROUTES.EDUCATION_FOURTH_SCHOOL_TYPE);
      }
    }
    if (grade === "fourth") {
      if (fourthGradeSchool.notFound) {
        navigate(ROUTES.EDUCATION_FOURTH_SCHOOL_NOT_FOUND);
      }
      else {
        if (fourthGradeSchool.sameSchool) {
          if (correcting)
            navigate(ROUTES.APPLICATION_SUMMARY);
          else
            navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
        }
        else
          navigate(ROUTES.EDUCATION_THIRD_SCHOOL_TYPE);
      }
    }
    if (grade === "third") {
      if (thirdGradeSchool.notFound)
        navigate(ROUTES.EDUCATION_THIRD_SCHOOL_NOT_FOUND);
      else {
        if (correcting)
          navigate(ROUTES.APPLICATION_SUMMARY);
        else
          navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
      }
    }
  }

  const handleContinue = (e) => {
    if (!canEdit) {
      navigateTo();
    }
    else {
      saveEducationInfo(e);
    }
  }

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid container direction="column" spacing={2}>
              {
                errMessages?.length ?
                  <Grid item xs={12}>
                    <Alert role="alert" severity="error">
                      {
                        errMessages.map((err, index) => (
                          <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                        ))
                      }
                    </Alert>
                  </Grid>
                  : <></>
              }
              {
                !canEdit ?
                  <>
                    <Grid item xs={12}>
                      <Alert role="alert" severity="warning">
                        <Typography>{t('common.already-confirmed')}</Typography>
                      </Alert>
                    </Grid>
                  </> : <></>
              }
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                    {
                      grade === "fifth" ? t('education.fifth-year.fifth-year-information.title') :
                        grade === "fourth" ? t('education.fourth-year.fourth-year-information.title') :
                          t('education.third-year.third-year-information.title')
                    }
                  </Typography>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <Stack>
                      <Autocomplete
                        disabled={!canEdit || (grade === "fifth" && fifthGradeSchool.notFound) || (grade === "fourth" && fourthGradeSchool.notFound) || (grade === "third" && thirdGradeSchool.notFound)}
                        noOptionsText={t('common.autocomplete.no-options')}
                        clearText={t('common.autocomplete.clear')}
                        loadingText={t('common.autocomplete.loading')}
                        freeSolo
                        open={openGeolocationList}
                        onOpen={() => {
                          setOpenGeolocationList(true);
                        }}
                        onClose={() => {
                          setOpenGeolocationList(false);
                        }}
                        getOptionLabel={(option) => option.geolocation}
                        options={geolocationList}
                        value={geolocation}
                        renderOption={(props, option, { inputValue }) => {
                          const matches = match(option.geolocation, inputValue, { insideWords: true });
                          const parts = parse(option.geolocation, matches);
                          props.key = props.id;
                          return (
                            <div key={option.geolocation} style={{ display: 'block' }} {...props}>
                              <p style={{ margin: '0' }}>
                                {parts.map((part, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: part.highlight ? 700 : 400,
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                              </p>
                            </div>
                          )
                        }
                        }
                        // filterOptions={filterOptions}
                        loading={loadingSearchResultsGeolocation}
                        onChange={handleGeolocationChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t('education.fifth-year.fifth-year-information.geolocation-placeholder')}
                            label={t('education.fifth-year.fifth-year-information.geolocation-label')}
                            value={searchKeyGeolocation}
                            onChange={handleSearchGeolocation}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingSearchResultsGeolocation ?
                                    <CircularProgress color="inherit" size={20} /> :
                                    <InputAdornment position="end">
                                      <SearchIcon />
                                    </InputAdornment>
                                  }
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack>
                      <Autocomplete
                        disabled={!canEdit || (grade === "fifth" && fifthGradeSchool.notFound) || (grade === "fourth" && fourthGradeSchool.notFound) || (grade === "third" && thirdGradeSchool.notFound)}
                        noOptionsText={t('common.autocomplete.no-options')}
                        clearText={t('common.autocomplete.clear')}
                        loadingText={t('common.autocomplete.loading')}
                        freeSolo
                        open={openSchoolList}
                        onOpen={() => {
                          setOpenSchoolList(true);
                        }}
                        onClose={() => {
                          setOpenSchoolList(false);
                        }}
                        // onBlur={() =>
                        // {
                        //   setSchoolList([])
                        // }}
                        getOptionLabel={(option) => option.name}
                        options={schoolList}
                        value={school}
                        renderOption={(props, option, { inputValue }) => {
                          const matches = match(option.name, inputValue, { insideWords: true });
                          const parts = parse(option.name, matches);
                          props.key = props.id;
                          return (
                            <div key={option.schoolId} style={{ display: 'block' }} {...props}>
                              <p style={{ margin: '0' }}>
                                {parts.map((part, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: part.highlight ? 700 : 400,
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                              </p>
                              <p style={{ margin: '0' }}><small>{option.address}</small></p>
                            </div>
                          )
                        }
                        }
                        // filterOptions={filterOptions}
                        loading={loadingSearchResultsSchools}
                        onChange={handleSchoolChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t('education.fifth-year.fifth-year-information.institution-name')}
                            label={t('education.fifth-year.fifth-year-information.institution-name')}
                            value={searchKeySchool}
                            onChange={handleSearchSchool}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingSearchResultsSchools ?
                                    <CircularProgress color="inherit" size={20} /> :
                                    <InputAdornment position="end">
                                      <SearchIcon />
                                    </InputAdornment>
                                  }
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  {
                    school && (
                      <Grid item xs={12}>
                        <Typography variant="body2" component="p"><b>{t('education.common.address')} {school.address}</b></Typography>
                      </Grid>
                    )
                  }
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox onChange={handleChangeSchoolNotFound} disabled={!canEdit} checked={grade === "fifth" ? fifthGradeSchool.notFound : grade === "fourth" ? fourthGradeSchool.notFound : thirdGradeSchool.notFound} inputProps={{ 'aria-label': 'controlled' }} />}
                      label={grade === "fifth" ? t('education.fifth-year.fifth-year-information.institution-not-found') : grade === "fourth" ? t('education.fourth-year.fourth-year-information.institution-not-found') : t('education.third-year.third-year-information.institution-not-found')}
                    />
                  </Grid>
                </Grid>
                {
                  (grade === "fifth" && !fifthGradeSchool.notFound) || (grade === "fourth" && !fourthGradeSchool.notFound) || (grade === "third" && !thirdGradeSchool.notFound) ?
                    <>
                      <Grid item container spacing={2}>
                        <Grid
                          item
                          xs={12}
                        >
                          <FormControl fullWidth>
                            <TextField inputProps={{ maxLength: 4 }}
                              disabled={!canEdit}
                              onKeyDown={handlePreventTypingChars}
                              fullWidth
                              value={
                                grade === "fifth" ? (fifthGradeSchool.schoolYear !== 0 ? fifthGradeSchool.schoolYear : '') :
                                  grade === "fourth" ? (fourthGradeSchool.schoolYear !== 0 ? fourthGradeSchool.schoolYear : '') :
                                    (thirdGradeSchool.schoolYear !== 0 ? thirdGradeSchool.schoolYear : '')
                              }
                              label={
                                grade === "fifth" ? t('education.fifth-year.fifth-year-institution.year-completed') :
                                  grade === "fourth" ? t('education.fourth-year.fourth-year-institution.year-completed') :
                                    t('education.third-year.third-year-institution.year-completed')
                              }
                              onChange={grade === "fifth" ? handleFifthYearChange : grade === "fourth" ? handleFourthYearChange : handleThirdYearChange}
                            />
                          </FormControl>
                          {
                            (grade === "fifth" ? errFifthYear.length : grade === "fourth" ? errFourthYear.length : errThirdYear.length) ?
                              <>
                                <Grid item>
                                  <div className="disclaimer validation-error">{grade === "fifth" ? errFifthYear : grade === "fourth" ? errFourthYear : errThirdYear}</div>
                                </Grid>
                              </> : <></>
                          }
                        </Grid>
                        {
                          (grade === "fifth" || grade === "fourth") ?
                            <>
                              <Grid
                                item
                                xs={12}
                              >
                                <FormControl fullWidth>
                                  <Typography variant="h5" component="h4">
                                    {grade === "fifth" ? t('education.fifth-year.fifth-year-institution.same-institute-question') : t('education.fourth-year.fourth-year-institution.same-institute-question')}
                                  </Typography>
                                  <RadioGroup
                                    value={grade === "fifth" ? (fifthGradeSchool.sameSchool ? 'yes' : 'no') : (fourthGradeSchool.sameSchool ? 'yes' : 'no')}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item xs={6}>
                                        <FormControlLabel value='yes' control={<Radio disabled={!canEdit} onClick={handleSameSchoolChange} checked={grade === "fifth" ? fifthGradeSchool.sameSchool !== null && fifthGradeSchool.sameSchool : fourthGradeSchool.sameSchool !== null && fourthGradeSchool.sameSchool} />} label={grade === "fifth" ? t('education.fifth-year.fifth-year-institution.same-institute-options.yes') : t('education.fourth-year.fourth-year-institution.same-institute-options.yes')} />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <FormControlLabel value='no' control={<Radio disabled={!canEdit} onClick={handleSameSchoolChange} checked={grade === "fifth" ? fifthGradeSchool.sameSchool !== null && !fifthGradeSchool.sameSchool : fourthGradeSchool.sameSchool !== null && !fourthGradeSchool.sameSchool} />} label={grade === "fifth" ? t('education.fifth-year.fifth-year-institution.same-institute-options.no') : t('education.fourth-year.fourth-year-institution.same-institute-options.no')} />
                                      </Grid>
                                    </Grid>
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              {(grade === "fifth" ? fifthGradeSchool.sameSchool : fourthGradeSchool.sameSchool) ?
                                <>
                                  <Grid item container spacing={2}>
                                    {
                                      grade === "fifth" ?
                                        <>
                                          <Grid
                                            item
                                            xs={12}
                                          >
                                            <FormControl fullWidth>
                                              <TextField inputProps={{ maxLength: 4 }}
                                                disabled={!canEdit}
                                                onKeyDown={handlePreventTypingChars}
                                                fullWidth
                                                value={fourthGradeSchool.schoolYear !== 0 ? fourthGradeSchool.schoolYear : ''}
                                                label={t('education.fifth-year.fifth-year-institution.year-completed-four-high-school')}
                                                onChange={handleFourthYearChange}
                                              />
                                            </FormControl>
                                            {
                                              errFourthYear.length ?
                                                <>
                                                  <Grid item>
                                                    <div className="disclaimer validation-error">{errFourthYear}</div>
                                                  </Grid>
                                                </> : <></>
                                            }
                                          </Grid>
                                        </> : <></>
                                    }
                                    <Grid
                                      item
                                      xs={12}
                                    >
                                      <FormControl fullWidth>
                                        <TextField inputProps={{ maxLength: 4 }}
                                          disabled={!canEdit}
                                          onKeyDown={handlePreventTypingChars}
                                          fullWidth
                                          value={thirdGradeSchool.schoolYear !== 0 ? thirdGradeSchool.schoolYear : ''}
                                          label={grade === "fifth" ? t('education.fifth-year.fifth-year-institution.year-completed-three-high-school') : t('education.fourth-year.fourth-year-institution.year-completed-three-high-school')}
                                          onChange={handleThirdYearChange}
                                        />
                                      </FormControl>
                                      {
                                        errThirdYear.length ?
                                          <>
                                            <Grid item>
                                              <div className="disclaimer validation-error">{errThirdYear}</div>
                                            </Grid>
                                          </> : <></>
                                      }
                                    </Grid>
                                  </Grid>
                                </> : <></>}
                            </> : <></>
                        }
                      </Grid>
                    </> : <></>
                }

                {
                  grade === "fifth" && schoolHasBachelorship ?
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h5" component="h4">
                          {t('education.bachelorship.title')}
                        </Typography>
                        <FormControl fullWidth>
                          <RadioGroup
                            value={bachelorship.hasBachelorship}
                          >
                            <Grid container spacing={2} >
                              <Grid item xs={6}>
                                <FormControlLabel
                                  value="yes"
                                  onChange={handleHasBachelorshipChange}
                                  disabled={!canEdit}
                                  control={<Radio checked={bachelorship.hasBachelorship !== null && bachelorship.hasBachelorship === "yes"} />}
                                  label={t('education.bachelorship.bachelorship-options.yes')}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormControlLabel
                                  value="no"
                                  onChange={handleHasBachelorshipChange}
                                  disabled={!canEdit}
                                  control={<Radio checked={bachelorship.hasBachelorship !== null && bachelorship.hasBachelorship === "no"} />}
                                  label={t('education.bachelorship.bachelorship-options.no')}
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                        {
                          bachelorship.hasBachelorship === "yes" ?
                            <Grid item xs={12}>
                              <TextField inputProps={{ maxLength: 4 }}
                                disabled={!canEdit}
                                onKeyDown={handlePreventTypingChars}
                                fullWidth
                                value={bachelorship.bachelorshipYear !== 0 ? bachelorship.bachelorshipYear : ''}
                                label={t('education.bachelorship.year-completed')}
                                onChange={handleBachelorshipYearChange}
                              />
                              {
                                errBachelorshipYear.length ?
                                  <>
                                    <Grid item>
                                      <div className="disclaimer validation-error">{errBachelorshipYear}</div>
                                    </Grid>
                                  </> : <></>
                              }
                            </Grid> : <></>
                        }
                      </Grid>
                    </> : <></>
                }

                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <ULButton
                      working={working}
                      onClick={handleContinue}
                      disabled={
                        grade === "fifth" ?
                          (
                            !fifthGradeSchool.notFound && (
                              fifthGradeSchool.schoolId <= 0 ||
                              !(fifthGradeSchool.schoolYear.toString().length === 4 && fifthGradeSchool.schoolYear > MIN_DATE && fifthGradeSchool.schoolYear <= currentYear) ||
                              !(!fifthGradeSchool.sameSchool || (fifthGradeSchool.sameSchool && fourthGradeSchool.schoolYear.toString().length === 4 && thirdGradeSchool.schoolYear.toString().length === 4 && fifthGradeSchool.schoolYear > fourthGradeSchool.schoolYear && fourthGradeSchool.schoolYear > thirdGradeSchool.schoolYear)) ||

                              (schoolHasBachelorship && (bachelorship.hasBachelorship === null || (bachelorship.hasBachelorship === "yes" && (bachelorship.bachelorshipYear.toString().length !== 4 || bachelorship.bachelorshipYear < fifthGradeSchool.schoolYear || bachelorship.bachelorshipYear > currentYear))))
                            )
                          ) || (!fifthGradeSchool.notFound && fifthGradeSchool.sameSchool === null) :
                          grade === "fourth" ?
                            (
                              !fourthGradeSchool.notFound && (
                                fourthGradeSchool.schoolId <= 0 ||
                                !(fourthGradeSchool.schoolYear.toString().length === 4 && fourthGradeSchool.schoolYear > MIN_DATE && fourthGradeSchool.schoolYear < fifthGradeSchool.schoolYear) ||
                                !(!fourthGradeSchool.sameSchool || (fourthGradeSchool.sameSchool && thirdGradeSchool.schoolYear.toString().length === 4 && fourthGradeSchool.schoolYear > thirdGradeSchool.schoolYear))
                              )
                            ) || (!fourthGradeSchool.notFound && fourthGradeSchool.sameSchool === null) :
                            (
                              !thirdGradeSchool.notFound && (
                                thirdGradeSchool.schoolId <= 0 || !thirdGradeSchool.schoolType ||
                                !(thirdGradeSchool.schoolYear.toString().length === 4 && thirdGradeSchool.schoolYear > MIN_DATE && thirdGradeSchool.schoolYear < fourthGradeSchool.schoolYear)
                              )
                            )
                      }
                    >
                      {
                        grade === "fifth" ? t('buttons.continue') :
                          grade === "fourth" ? t('buttons.continue') :
                            t('buttons.continue')
                      }
                    </ULButton>
                  </Grid>
                  <Grid item xs={12}
                  >
                    <ULButton
                      variant="outlined"
                      onClick={
                        () => navigate(
                          grade === "fifth" ? ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE :
                            grade === "fourth" ? ROUTES.EDUCATION_FOURTH_SCHOOL_TYPE :
                              ROUTES.EDUCATION_THIRD_SCHOOL_TYPE
                        )
                      }
                      goBack={true}
                    >
                      {t('buttons.back')}
                    </ULButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
      }
    </>
  );
}

export default EducationLocalSchoolData; 